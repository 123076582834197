import * as React from 'react';

function useLockBodyScroll(lock) {
  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined' && lock) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = originalStyle);
    }
  }, [lock]);
}

export default useLockBodyScroll;
