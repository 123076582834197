import * as React from 'react';
import { LogoSvg } from './assets';
import { useTheme } from 'emotion-theming';


function Logo(props) {
  const { width, fullWidth, minWidth } = props;
  const theme = useTheme();

  return (
    <LogoSvg
      css={{
        minWidth,
        width: fullWidth ? undefined : width,
        fill: theme.colors.background,
      }}
    />
  );
}

Logo.defaultProps = {
  variant: 'filled',
  width: 120,
};

export default Logo;
