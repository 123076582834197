import * as React from 'react';

function useMedia(query) {
  const mediaQuery = typeof window !== 'undefined' && window.matchMedia(query);

  const getValue = React.useCallback(()=> {
    return mediaQuery.matches;
  },[mediaQuery.matches])

  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQuery.addListener(handler);
    handler();
    return () => mediaQuery.removeListener(handler);
  }, [getValue, mediaQuery]);

  return value;
}

export default useMedia;
